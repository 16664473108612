import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ChartistModule } from 'ng-chartist';
import { MarkdownModule } from 'ngx-markdown';
import { DragDropDirective } from 'src/app/shared/directives/drag-drop.directive';
import { FileSizePipe } from 'src/app/shared/pipes/file-size.pipe';
import { SearchPipe } from 'src/app/shared/pipes/notification-filter.pipe';
import { ReplaceNewLinePipe } from 'src/app/shared/pipes/replace-new-line.pipe';
import { SpaceCapitalsPipe } from 'src/app/shared/pipes/space-capitals.pipe';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';
import { CapitalLetterPipe } from '../../shared/pipes/capital-letter.pipe';
import { DateAgoPipe } from '../../shared/pipes/date-ago.pipe';
import { ThousandsPipe } from '../../shared/pipes/thousands.pipe';
import { LoadingModalComponent } from '../automation-testing/request-test/loading-modal/loading-modal.component';
import { WarningModalComponent } from '../publish/warning-modal/warning-modal.component';
import { TableFindingsComponent } from '../security/security-findings/table-findings/table-findings.component';
import { AppInfoStatusCardComponent } from './app-info-status-card/app-info-status-card.component';
import { AppToggleButtonComponent } from './app-toggle-button/app-toggle-button.component';
import { ContainerGradientComponent } from './container-gradient/container-gradient.component';
import { EngageComponent } from './engage/engage.component';
import { FooterIssuesComponent } from './footer-issues/footer-issues.component';
import { GeneralModalComponent } from './general-modal/general-modal.component';
import { LiveVersionSecurityIssuesBannerComponent } from './live-version-security-issues-banner/live-version-security-issues-banner.component';
import { LoadingSpinnerComponent } from './loading-spinner-modal/loading-spinner-modal.component';
import { NotificationToastComponent } from './notification-bell/notifiaction-toast/notification-toast.component';
import { NotificationDotComponent } from './notification-bell/notification-dot/notification-dot.component';
import { NotificationItemComponent } from './notification-bell/notification-item/notification-item.component';
import { NotificationModalComponent } from './notification-bell/notification-modal/notification-modal.component';
import { Page404Component } from './page404/page404.component';
import { RatingOverlayComponent } from './rating-overlay/rating-overlay.component';
import { RiskAcceptanceComponent } from './risk-acceptance/risk-acceptance.component';
import { SuccessDialogModalComponent } from './success-dialog/success-dialog.component';
import { AppButtonComponent } from './ui/app-button/app-button.component';
import { AppFeedbackModalComponent } from './ui/app-feedback-modal/app-feedback-modal.component';
import { AppCardComponent } from './ui/app-card/app-card.component';
import { AppDotComponent } from './ui/app-dot/app-dot.component';
import { AppInputComponent } from './ui/app-input/app-input.component';
import { AppLoaderComponent } from './ui/app-loader/app-loader.component';
import { AppTableComponent } from './ui/app-table/app-table.component';
import { AppTabsComponent } from './ui/app-tabs/app-tabs.component';
import { UploadEvidenceComponent } from './upload-evidence/upload-evidence.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { WhitelabelWarningComponent } from './whitelabel-warning/whitelabel-warning.component';
import { AppModalComponent } from './ui/app-modal/app-modal.component';
import { AppTooltipComponent } from './ui/app-tooltip/app-tooltip.component';

@NgModule({
  declarations: [
    ContainerGradientComponent,
    FooterIssuesComponent,
    GeneralModalComponent,
    LoadingSpinnerComponent,
    RatingOverlayComponent,
    DateAgoPipe,
    ThousandsPipe,
    CapitalLetterPipe,
    TimeAgoPipe,
    ReplaceNewLinePipe,
    SearchPipe,
    UploadFileComponent,
    UploadEvidenceComponent,
    DragDropDirective,
    Page404Component,
    NotificationToastComponent,
    EngageComponent,
    LoadingModalComponent,
    WarningModalComponent,
    SuccessDialogModalComponent,
    NotificationDotComponent,
    NotificationItemComponent,
    NotificationModalComponent,
    RiskAcceptanceComponent,
    WhitelabelWarningComponent,
    TableFindingsComponent,
    LiveVersionSecurityIssuesBannerComponent,
    AppButtonComponent,
    AppTabsComponent,
    FileSizePipe,
    AppToggleButtonComponent,
    AppInfoStatusCardComponent,
    AppCardComponent,
    AppInputComponent,
    AppTableComponent,
    AppDotComponent,
    AppLoaderComponent,
    SpaceCapitalsPipe,
    AppFeedbackModalComponent,
    AppModalComponent,
    AppTooltipComponent,
  ],

  imports: [
    CommonModule,
    FontAwesomeModule,
    MatIconModule,
    ChartistModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTabsModule,
    TranslateModule.forChild(),
    MarkdownModule.forChild(),
    MatTableModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  exports: [
    ContainerGradientComponent,
    GeneralModalComponent,
    LoadingSpinnerComponent,
    RatingOverlayComponent,
    CapitalLetterPipe,
    FontAwesomeModule,
    ChartistModule,
    DateAgoPipe,
    ThousandsPipe,
    CapitalLetterPipe,
    TimeAgoPipe,
    ReplaceNewLinePipe,
    SearchPipe,
    ReplaceNewLinePipe,
    UploadFileComponent,
    UploadEvidenceComponent,
    TranslateModule,
    MarkdownModule,
    MatPaginatorModule,
    LoadingModalComponent,
    WarningModalComponent,
    DragDropDirective,
    SuccessDialogModalComponent,
    MatIconModule,
    NotificationDotComponent,
    MatTabsModule,
    NotificationItemComponent,
    NotificationModalComponent,
    RiskAcceptanceComponent,
    WhitelabelWarningComponent,
    TableFindingsComponent,
    LiveVersionSecurityIssuesBannerComponent,
    AppButtonComponent,
    AppTabsComponent,
    AppToggleButtonComponent,
    AppInfoStatusCardComponent,
    AppCardComponent,
    AppInputComponent,
    AppTableComponent,
    AppDotComponent,
    AppLoaderComponent,
    SpaceCapitalsPipe,
    AppTooltipComponent,
  ],
})
export class SharedModule {}
