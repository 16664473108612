import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  //patterns
  DigipiAcceptedPattern: RegExp = /^(?:[A-Za-z]{3}\d{7}|null|)$/;
  DigipiPattern: RegExp = /^[a-zA-Z]{0,3}\d{0,7}$/;
  // technologies
  tech_iOS: string = 'ios';
  tech_Android: string = 'android';

  // states
  state_success = 'success';
  state_loading = 'loading';
  state_error = 'error';

  //sections
  DASHBOARD = 'dashboard';
  DEVELOP = 'develop';
  CONFIGURE = 'configure';
  USERMANAGEMENT = 'user_management';
  APPDISTRIBUTION = 'release';
  TESTING = 'testing';
  SECURITY = 'security';
  PUBLISH = 'publish';
  ENGAGE = 'engage';
  MEASURE = 'measure';
  COMMUNICATION = 'communication';

  //actions
  ACC_LATEST_VERSION = 'latest_version';
  ACC_LIVE_STATS = 'live_stats';
  ACC_LATEST_CHANGES = 'latest_changes';
  ACC_QUALITY_GATE = 'quality_gate';
  ACC_REQUEST_PUBLICATION = 'request_publication';
  ACC_CREATE_RELEASE = 'create_release';
  ACC_RELEASE_LIST = 'release_list';
  ACC_DOWNLOAD_REPORT = 'download_report';
  ACC_REQUEST_ASSESSMENT = 'request_assessment';
  ACC_SCAN_LIST = 'scan_list';
  ACC_REQUEST_TEST = 'request_test';
  ACC_INAPP_MARKETING = 'inapp_marketing';
  ACC_REPLY_TO_REVIEWS = 'reply_to_reviews';
  ACC_LIST_FUNCTIONAL_CHECKS = 'list_functional_checks';
  ACC_LIVE_SECURITY_BANNER = 'live_security_banner';
  ACC_NOTIFICATIONS_BELL = 'notifications_bell';

  //FEATURE FLAGS
  enableAutomationTesting = false;

  FF_DOWNLOAD_SYM = 'download_symbols';

  //CARDS

  //latest Version
  CARD_QUAL_SUCCESS = 'OK';
  CARD_QUAL_ERROR = 'ERROR';
  CARD_QUAL_UNAVAILABLE = 'UNAVAILABLE';

  //latest scan

  //Request assesment
  // REQUEST_FULL_BUTTON = "REQUEST FULL"
  // REQUEST_REM_BUTTON = "REQUEST REM"
  // REQUEST_BUTTON = "REQUEST"

  //Tour Permissions
  tour_all_sections = {
    sections: [
      { code: 'configure', actions: [{ code: 'redirect' }] },
      {
        code: 'dashboard',
        actions: [{ code: 'latest_version' }, { code: 'live_stats' }],
      },
      {
        code: 'develop',
        actions: [
          { code: 'job_launch' },
          { code: 'job_log' },
          { code: 'latest_changes' },
          { code: 'quality_gate' },
        ],
      },
      { code: 'measure', actions: [{ code: 'redirect' }] },
      { code: 'publish', actions: [{ code: 'request_publication' }] },
      {
        code: 'release',
        actions: [
          { code: 'create_release' },
          { code: 'job_launch' },
          { code: 'job_log' },
          { code: 'release_list' },
        ],
      },
      {
        code: 'security',
        actions: [
          { code: 'download_report' },
          { code: 'request_assessment' },
          { code: 'scan_list' },
        ],
      },
      { code: 'testing', actions: [{ code: 'request_test' }] },
      {
        code: 'engage',
        actions: [{ code: 'inapp_marketing' }, { code: 'reply_to_reviews' }],
      },
    ],
  };
}

export enum SECTIONS_PAGE {
  MAIN = 'main',
  DASHBOARD = 'dashboard',
  DEVELOP = 'develop',
  CONFIGURE = 'configure',
  APPDISTRIBUTION = 'release',
  TESTING = 'testing',
  SECURITY = 'security',
  PUBLISH = 'publish',
  ENGAGE = 'engage',
  MEASURE = 'measure',
  USERMANAGEMENT = 'user_management',
}

export enum SOURCE_TYPE {
  WHITELABEL = 'WhiteLabel',
  NATIVE = 'Native',
  HYBRID = 'Hybrid',
}

//SECURITY
export enum ENVIRONMENT {
  PRE = 'pre',
  LIVE = 'live',
}
