import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModalData } from './appModalData';
import { ModalType } from '../models/modal-type.enum';

@Component({
  selector: 'app-modal',
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.scss'],
})
export class AppModalComponent {
  confirmationModalType: ModalType;
  constructor(
    public dialogRef: MatDialogRef<AppModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppModalData,
  ) {}

  defineIconImage() {
    switch (this.data.type) {
      case ModalType.Info:
        return 'assets/images/icons/Icon-Alert-Information.svg';
      case ModalType.Error:
        return 'assets/images/icons/Icon-Alert-Error.svg';
      case ModalType.Warning:
        return 'assets/images/icons/Icon-Alert-Warning.svg';
      case ModalType.Success:
        return 'assets/images/icons/Icon-Alert-Success.svg';
    }
  }
  onButtonYesClick() {
    this.dialogRef.close(true);
  }
  onButtonNoClick() {
    this.dialogRef.close();
  }
}
