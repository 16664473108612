import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppVersion } from 'src/app/model/appVersion';
import { Commit } from 'src/app/model/commit.model';
import { AppStatusService } from 'src/app/modules/dashboard/services/app-status.service';

@Component({
  selector: 'app-status-overview',
  templateUrl: './status-overview.component.html',
  styleUrls: ['./status-overview.component.scss'],
  providers: [DatePipe],
})
export class StatusOverviewComponent implements OnInit, OnChanges {
  @Input() appVersions: AppVersion[];
  @Input() commitList: Commit[];
  @Input() projectQualityObject: any;

  imageQualityGate: string = '../../../assets/images/icons/nodata.svg';
  feedbackMessage: string;

  constructor(
    private translate: TranslateService,
    private appStatus: AppStatusService,
    private datePipe: DatePipe,
  ) {
    this.feedbackMessage = this.translate.instant(
      'component.dashboard.latestchanges.quality-automation-security-not-available',
    );
  }

  ngOnInit(): void {
    this.setStatus();
  }

  ngOnChanges() {
    this.setStatus();
  }

  setStatus() {
    const qualityGate =
      this.commitList && this.commitList.length ? true : false;
    if (this.projectQualityObject?.app_status === 'SUCCESSFUL') {
      this.imageQualityGate = '../../../assets/images/icons/success.svg';
      this.feedbackMessage = this.translate.instant(
        'component.dashboard.latestchanges.quality-automation-security-success',
      );
    } else if (this.projectQualityObject?.app_status === 'FAILED') {
      this.imageQualityGate =
        '../../../assets/images/icons/Icon-Alert-Error.svg';
      this.feedbackMessage = this.translate.instant(
        'component.dashboard.latestchanges.quality-automation-security-error',
      );
    } else {
      this.imageQualityGate = '../../../assets/images/icons/nodata.svg';
      this.feedbackMessage = this.translate.instant(
        'component.dashboard.latestchanges.quality-automation-security-not-available',
      );
    }
  }

  updateStatusImage(item: AppVersion, section: string) {
    const success = '../../../../assets/images/icons/success.svg';
    const failure = '../../../../assets/images/icons/Icon-Alert-Error.svg';
    const loading = '../../../assets/images/icons/loading.png';
    const published = '../../../../assets/images/icons/ok-hand.svg';

    if (!item[section].status) {
      return '';
    }

    if (item[section].status === 'PROGRESS') {
      return loading;
    }

    if (item[section].status === 'SUCCESS') {
      return success;
    }

    if (item[section].status === 'FAILURE') {
      return failure;
    }

    if (item[section].status === 'PUBLISHED') {
      return published;
    }

    return '';
  }

  goToAppDistribution() {
    this.appStatus.sectionNavigate.next({ link: 'release' });
  }

  showStatusBar(item: AppVersion): boolean {
    return item.request_publication.status === 'PUBLISHED';
  }

  showLoading(item: AppVersion, section: string) {
    return {
      loading: item[section].status === 'PROGRESS',
      published:
        section === 'request_publication' &&
        item[section].status === 'PUBLISHED',
    };
  }

  toolTipMessage(item: AppVersion, section: string) {
    if (item[section].info) {
      const date = item[section].date
        ? this.datePipe.transform(item[section].date)
        : '';

      return date + ' ' + item[section].info;
    }

    return section;
  }
}
