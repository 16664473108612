import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { Globals, SECTIONS_PAGE } from './Utils/globals';
import { EngageComponent } from './modules/shared/engage/engage.component';
import { AuthResolver } from './core/resolvers/auth.resolver';
import { AuthGuard } from './core/guards/auth.guard';
import { RedirectResolver } from './core/resolvers/redirect.resolver';
import { LandingNoAppComponent } from './modules/shared/landing-no-app/landing-no-app.component';

const GlobalsInstance = new Globals();
const automationTestingRoutes = GlobalsInstance.enableAutomationTesting
  ? [
      {
        path: `:app/${SECTIONS_PAGE.TESTING}`,
        loadChildren: () =>
          import('./modules/automation-testing/automation-testing.module').then(
            (m) => m.AutomationTestingModule,
          ),
        data: { section: SECTIONS_PAGE.TESTING },
      },
    ]
  : [];

const dashboardModule = import('./modules/dashboard/dashboard.module').then(
  (m) => m.DashboardModule,
);

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: { authData: AuthResolver },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/landing-page/landing-page.module').then(
            (m) => m.LandingPageModule,
          ),
      },
      { path: 'Landingnoapp', component: LandingNoAppComponent },
      {
        path: `onboarding`,
        loadChildren: () =>
          import('./modules/onboarding/onboarding.module').then(
            (m) => m.OnboardingModule,
          ),
      },
      {
        path: `:app/${SECTIONS_PAGE.DASHBOARD}`,
        loadChildren: () => dashboardModule,
        data: { section: SECTIONS_PAGE.DASHBOARD },
      },
      ...automationTestingRoutes,
      {
        path: `:app/${SECTIONS_PAGE.CONFIGURE}`,
        loadChildren: () =>
          import('./modules/configure/configure.module').then(
            (m) => m.ConfigureModule,
          ),
        data: { section: SECTIONS_PAGE.CONFIGURE },
      },
      {
        path: `:app/${SECTIONS_PAGE.USERMANAGEMENT}`,
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule,
          ),
        data: { section: SECTIONS_PAGE.USERMANAGEMENT },
      },
      {
        path: `:app/${SECTIONS_PAGE.DEVELOP}`,
        loadChildren: () =>
          import('./modules/develop/develop.module').then(
            (m) => m.DevelopModule,
          ),
        data: { section: SECTIONS_PAGE.DEVELOP },
      },
      {
        path: `:app/${SECTIONS_PAGE.APPDISTRIBUTION}`,
        loadChildren: () =>
          import('./modules/distribution/distribution.module').then(
            (m) => m.DistributionModule,
          ),
        data: { section: SECTIONS_PAGE.APPDISTRIBUTION },
      },
      {
        path: `:app/${SECTIONS_PAGE.SECURITY}`,
        loadChildren: () =>
          import('./modules/security/security.module').then(
            (m) => m.SecurityModule,
          ),
        data: { section: SECTIONS_PAGE.SECURITY },
      },
      {
        path: `:app/${SECTIONS_PAGE.PUBLISH}`,
        loadChildren: () =>
          import('./modules/publish/publish.module').then(
            (m) => m.PublishModule,
          ),
        data: { section: SECTIONS_PAGE.PUBLISH },
      },
      {
        path: `:app/${SECTIONS_PAGE.ENGAGE}`,
        component: EngageComponent,
      },
      {
        path: 'tour',
        loadChildren: () => dashboardModule,
      },
      {
        path: '**',
        loadChildren: () => dashboardModule,
        resolve: { redirect: RedirectResolver },
      },
    ],
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  useHash: false,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
