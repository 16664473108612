<div class="app-confirmation-modal">
  <ng-container>
    <img
      class="app-confirmation-modal-icon"
      width="98.33"
      height="98.33"
      [src]="defineIconImage()"
      alt=""
    />
    <h1 class="app-confirmation-modal-title">{{ data.title }}</h1>
    <p class="app-confirmation-modal-subtext">
      {{ data.description }}
    </p>
    <p class="app-confirmation-modal-subtext-detail">
      {{ data.detailDescription }}
    </p>
  </ng-container>
  <div class="app-confirmation-modal-buttons">
    <app-button
      (clickEvent)="onButtonNoClick()"
      [text]="data.buttonNoText"
    ></app-button>
    <app-button
      *ngIf="data.buttonYesText"
      class="btn-accept"
      (clickEvent)="onButtonYesClick()"
      [text]="data.buttonYesText"
      [submitType]="true"
    ></app-button>
  </div>
</div>
