import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Application } from '../model/application.model';
import { jsonResponseApi } from '../model/json-response.model';
import { DialogService } from './dialog.service';
import { environment } from 'src/environments/environment';
import { Globals } from 'src/app/Utils/globals';
import { AppModalComponent } from '../modules/shared/ui/app-modal/app-modal.component';
import { AppModalData } from '../modules/shared/ui/app-modal/appModalData';
import { ModalType } from '../modules/shared/ui/models/modal-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private sanitizer: DomSanitizer,
    public httpClient: HttpClient,
    public globals: Globals,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  public get(url, params?) {
    return this.httpClient.get(environment.urls.apiBaseUrl + url, {
      params: params,
      withCredentials: true,
    });
  }

  public post(url, params) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + url,
      params,
      { withCredentials: true },
    );
  }

  jsonParse(pObjJSON: jsonResponseApi): any {
    if (pObjJSON.is_ok == true) {
      return JSON.parse(pObjJSON.original_response);
    } else {
      return 'false';
    }
  }

  stringParser(data): string {
    if (data.is_ok == true) {
      return data.original_response;
    } else {
      return 'false';
    }
  }

  uploadFile(url, file, params) {
    const headers = {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-meta-package': params.package,
        'x-ms-meta-technology': params.technology,
        'x-ms-meta-commit_id': params.parameters.commit_id,
        'x-ms-meta-from_branch': params.parameters.from_branch,
        'x-ms-meta-auto_distribute':
          params.parameters.auto_distribute.toString(),
        'x-ms-meta-groups': params.parameters.groups,
      },
    };
    if (params.technology === 'android' || !params.parameters.groups) {
      delete headers.headers['x-ms-meta-groups'];
    }

    return this.httpClient.put(url, file, headers);
  }

  sliceFile(file: any, maxSizeChunk: number): any[] {
    const nChunks: any = Math.ceil(file.size / maxSizeChunk);
    var lChunks = [];

    for (let n = 0; n <= nChunks - 1; n++) {
      if (n === nChunks - 1) {
        lChunks.push(
          file.slice(
            n * maxSizeChunk,
            (file.size % maxSizeChunk) + n * maxSizeChunk,
          ),
        );
      } else {
        lChunks.push(
          file.slice(n * maxSizeChunk, maxSizeChunk + n * maxSizeChunk),
        );
      }
    }

    return lChunks;
  }

  uploadFilePutBlock(url, file, id) {
    url = url + '&comp=block&blockid=' + id;
    return this.httpClient.put(url, file);
  }

  uploadFilePutBlockList(url, xml, pHeaders) {
    url = url + '&comp=blocklist';
    return this.httpClient.put(url, xml, pHeaders);
  }

  getExtensionByTech(tech) {
    if (tech == 'android') {
      return ['aab'];
    } else if (tech == 'ios') {
      return ['ipa'];
    }
  }

  downloadFile(blob: any, filetype: string, filename: string) {
    var binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(
      new Blob(binaryData, { type: filetype }),
    ); // <-- work with blob directly
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
  }

  downloadFileByUrl(url: string, filename: string) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
  }

  generateDownloadUri(response, type) {
    const blob = new Blob([response], { type: type });
    const url = window.URL.createObjectURL(blob);
    const uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return uri;
  }

  copyToClipboard(item) {
    navigator.clipboard.writeText(item);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  charOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 95 && charCode <= 122) ||
      charCode == 8 ||
      charCode == 32
    ) {
      return true;
    }
    return false;
  }

  digipiNumbersOnly(event) {
    const input = (event.target as HTMLInputElement).value + event.key;
    return !(input && !this.globals.DigipiPattern.test(input));
  }

  public getCrossFormUrl(params: object, psKey: string) {
    return this.httpClient.post<any>(
      environment.urls.apiBaseUrl + 'cross/formurl/' + psKey,
      params,
      { withCredentials: true },
    );
  }

  public optNotAvailable(): void {
    const objDialog: DialogService = new DialogService(this.dialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/error.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: '',
      main: this.translate.instant('component.general-modal.opt-not-available'),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: this.translate.instant('component.general-modal.accept'),
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }
  public dlgInfo(psTitle: string = '', psMsgTxt: string = ''): void {
    const objDialog: DialogService = new DialogService(this.dialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/info.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: psTitle,
      main: psMsgTxt,
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: 'OK',
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }

  public apiError(error, errorMessage?: string): void {
    const errorInfo = errorMessage
      ? errorMessage
      : this.translate.instant('component.error-dialog.subtext');

    const texts = {
      text: this.translate.instant('component.error-dialog.text'),
      subtext: errorInfo,
      subtext2: this.translate.instant('component.error-dialog.subtext2', {
        error: error,
      }),
      btntext: this.translate.instant('common.close'),
    };

    const modalData: AppModalData = {
      type: ModalType.Error,
      title: texts.text,
      detailDescription: texts.subtext + texts.subtext2,
      buttonNoText: texts.btntext,
    };

    const dialogRef = this.dialog.open(AppModalComponent, {
      panelClass: 'dialogPanelClass',
      data: modalData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public uploadSuccess(): void {
    const objDialog: DialogService = new DialogService(this.dialog);

    objDialog.icon = {
      src: '../../../assets/images/icons/success.svg',
      class: 'clsIcon80x80',
    };

    objDialog.texts = {
      title: '',
      main: this.translate.instant('component.general-modal.upload-success'),
      subtext: '',
    };

    objDialog.buttons = [
      {
        btnText: this.translate.instant('component.general-modal.accept'),
        btnFunc: function (pThisDlg: any) {
          pThisDlg.close();
        },
      },
    ];

    objDialog.openDialog();
  }

  public openLinkOnTab(psUrl): void {
    window.open(psUrl, '_blank');
  }

  public setDataLocalstorage(pObjApp: Application): void {
    const lObjData = {
      i: pObjApp.id,
      p: pObjApp.package,
      t: pObjApp.tech,
    };
    localStorage.setItem('data', JSON.stringify(lObjData));
  }

  public getDataLocalstorage(): void {
    const lObjData = JSON.parse(localStorage.getItem('data'));
    return lObjData;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  loadAppSelected(pArrApplications: Array<Application>, pObjAppSelected: any) {
    for (let i = 0; i <= pArrApplications.length - 1; i++) {
      const item = pArrApplications[i];
      if (
        item.id == pObjAppSelected.i &&
        item.package == pObjAppSelected.p &&
        item.tech == pObjAppSelected.t
      ) {
        return item;
      }
    }
    return pArrApplications[0];
  }

  private getCurrentSection(): string {
    const lObjSectionSelected =
      document.getElementsByClassName('clsMenuSelect');

    if (lObjSectionSelected && lObjSectionSelected.length === 1) {
      return lObjSectionSelected[0].parentElement.getAttribute('section');
    }

    return '';
  }

  isThisSectionSelected(psSection: string): boolean {
    return psSection === this.getCurrentSection();
  }

  isPortalActionVisible(
    application: Application,
    actionToCheck: string,
    sectionName: string,
  ): boolean {
    const action = application?.permissions?.sections
      ?.find((section) => section.code === sectionName)
      ?.actions?.find((action) => action.code === actionToCheck);
    return !!action;
  }
}
